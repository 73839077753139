import React, { useState, useEffect } from 'react';
import HodNavbar from './HodNavbar';
import './HstudentDetail.css';
import { baseurl } from '../../url';

function AssignDivisionPage() {
  const [branch, setBranch] = useState('');
  const [students, setStudents] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [divisionUpdates, setDivisionUpdates] = useState({}); // Stores division updates
  const [numDivisions, setNumDivisions] = useState(0); // Number of divisions needed

  // Fetch students based on branch
  useEffect(() => {
    const fetchStudents = async (branch) => {
      try {
        const response = await fetch(`${baseurl}/api/students/${branch}`);
        if (!response.ok) {
          throw new Error('Failed to fetch student details');
        }
        const data = await response.json();
        setStudents(data.students);

        const uniqueCourses = [...new Set(data.students.map(student => student.course))];
        setCourseOptions(['All', ...uniqueCourses]);
      } catch (error) {
        console.error(error.message);
      }
    };

    const storedBranch = localStorage.getItem('branch');
    if (storedBranch) {
      setBranch(storedBranch);
      fetchStudents(storedBranch);
    }
  }, []);

  // Filter students based on selected semester and course
  useEffect(() => {
    let newFilteredStudents = [...students];
    if (selectedSemester !== '' && selectedSemester !== 'All') {
      newFilteredStudents = newFilteredStudents.filter((student) => String(student.semester) === selectedSemester);
    }
    if (selectedCourse && selectedCourse !== 'All') {
      newFilteredStudents = newFilteredStudents.filter((student) => student.course.includes(selectedCourse));
    }
    setFilteredStudents(newFilteredStudents);
  }, [students, selectedSemester, selectedCourse]);

  // Handle semester change
  const handleSemesterChange = (event) => {
    setSelectedSemester(event.target.value);
  };

  // Handle course change
  const handleCourseChange = (event) => {
    setSelectedCourse(event.target.value);
  };

  // Handle division change for each student
  const handleDivisionChange = (studentId, division) => {
    setDivisionUpdates((prev) => ({ ...prev, [studentId]: division }));
  };

  // Randomly assign divisions to all students based on the specified number of divisions, ensuring alphabetical order
  const handleAssignDivisionsToAll = () => {
    if (numDivisions <= 0 || numDivisions > 26) {
      alert('Please enter a valid number of divisions (between 1 and 26)');
      return;
    }

    // Sort students alphabetically by name
    const sortedStudents = [...filteredStudents].sort((a, b) => a.name.localeCompare(b.name));

    const divisions = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.slice(0, numDivisions).split('');
    const updatedDivisions = {};

    // Round-robin distribute students equally across divisions
    sortedStudents.forEach((student, index) => {
      const divisionIndex = index % numDivisions;
      updatedDivisions[student._id] = divisions[divisionIndex];
    });

    setDivisionUpdates(updatedDivisions);
  };

  // Submit division assignments
  const handleSubmit = async () => {
    console.log('Submitting division updates:', divisionUpdates);
    try {
      const response = await fetch(`${baseurl}/api/students/update-division`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(divisionUpdates),
      });

      if (!response.ok) {
        throw new Error('Failed to update divisions');
      }
      alert('Divisions updated successfully!');
    } catch (error) {
      console.error(error.message);
      alert('Error updating divisions');
    }
  };

  return (
    <div>
      <HodNavbar />
      <div className="student-details-container">
        <h3 className="student-details-title">Branch: {branch}</h3>
        
        {/* Filters Section */}
        <div className="student-details-filters">
          <div className="student-details-label-select">
            <label htmlFor="course">Select Course:</label>
            <select id="course" value={selectedCourse} onChange={handleCourseChange}>
              {courseOptions.map((course, index) => (
                <option key={index} value={course}>{course}</option>
              ))}
            </select>
          </div>
          <div className="student-details-label-select">
            <label htmlFor="semester">Select Semester:</label>
            <select id="semester" value={selectedSemester} onChange={handleSemesterChange}>
              <option value="">All</option>
              <option value="1">Semester 1</option>
              <option value="2">Semester 2</option>
              <option value="3">Semester 3</option>
              <option value="4">Semester 4</option>
              <option value="5">Semester 5</option>
              <option value="6">Semester 6</option>
              <option value="7">Semester 7</option>
              <option value="8">Semester 8</option>
            </select>
          </div>

          {/* Input to set the number of divisions */}
          <div className="student-details-label-select">
            <label htmlFor="divisions">Number of Divisions:</label>
            <input
              id="divisions"
              type="number"
              value={numDivisions}
              onChange={(e) => setNumDivisions(parseInt(e.target.value) || 0)}
              min="1"
              max="26"
            />
          </div>

          {/* Button to auto-assign divisions */}
          <button onClick={handleAssignDivisionsToAll} className="assign-all-btn">
            Assign Divisions to All
          </button>
        </div>

        {/* Student List with Division Assignment */}
        <div className="student-details-table-container">
          <table className="student-details-table">
            <thead>
              <tr>
                <th>Admission Number</th>
                <th>Name</th>
                <th>Course</th>
                <th>Semester</th>
                <th>Division</th>
              </tr>
            </thead>
            <tbody>
              {filteredStudents.map((student) => (
                <tr key={student._id}>
                  <td>{student.admissionNumber}</td>
                  <td>{student.name}</td>
                  <td>{student.course}</td>
                  <td>{student.semester}</td>
                  <td>
                    <select
                      value={divisionUpdates[student._id] || student.division || 'None'}
                      onChange={(e) => handleDivisionChange(student._id, e.target.value)}
                    >
                      <option value="None">None</option>
                      {Array.from({ length: numDivisions }, (_, i) => (
                        <option key={i} value={String.fromCharCode(65 + i)}>
                          {String.fromCharCode(65 + i)}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Submit button to save division updates */}
          <button onClick={handleSubmit} className="submit-division-btn">
            Submit Divisions
          </button>
        </div>
      </div>
    </div>
  );
}

export default AssignDivisionPage;
