import React, { useState, useEffect } from 'react';
import Navbar from './OfficerNavbar';
import { baseurl } from '../../url';
import './Feepayment.css';

function StudentDetailsPage() {
  const [students, setStudents] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedSemester, setSelectedSemester] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [selectedInstallment, setSelectedInstallment] = useState(''); // State for selected installment

  useEffect(() => {
    fetchStudentDetails();
  }, []);

  const fetchStudentDetails = async () => {
    try {
      const response = await fetch(`${baseurl}/api/officer/details`);
      if (!response.ok) {
        throw new Error('Failed to fetch student details');
      }
      const data = await response.json();
      setStudents(data.students);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleFeePayment = async (studentId, installmentIndex) => {
    try {
      const response = await fetch(`${baseurl}/api/officer/fee-payment/${studentId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ installmentIndex }),
      });
      if (!response.ok) {
        throw new Error('Failed to submit fee payment');
      }
      fetchStudentDetails(); // Refresh student details after fee payment
    } catch (error) {
      console.error('Fee payment error:', error.message);
    }
  };

  const handleSendEmail = async (studentId, installmentIndex) => {
    if (!lastDate) {
      alert('Please enter the last date for payment.');
      return;
    }

    try {
      const response = await fetch(`${baseurl}/api/officer/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ studentId, installmentIndex, lastDate }),
      });
      if (!response.ok) {
        throw new Error('Failed to send email');
      }
      alert('Reminder email sent to the student.');
    } catch (error) {
      console.error('Email send error:', error.message);
    }
  };

  const handleSendEmailToAll = async () => {
    if (!selectedInstallment) {
      alert('Please select an installment number.');
      return;
    }
    if (!lastDate) {
      alert('Please enter the last date for payment.');
      return;
    }

    // Filter students based on selected course and semester before sending emails
    const filteredStudents = students.filter((student) => {
      const courseMatch = !selectedCourse || student.course === selectedCourse;
      const semesterMatch = !selectedSemester || (student.semester && student.semester.toString() === selectedSemester);
      return courseMatch && semesterMatch && (!student.installmentsPaid || !student.installmentsPaid.includes(selectedInstallment));
    });

    if (filteredStudents.length === 0) {
      alert('No students found for the selected criteria.');
      return;
    }

    try {
      const response = await fetch(`${baseurl}/api/officer/send-email-to-all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ filteredStudents, selectedInstallment, lastDate }),
      });
      if (!response.ok) {
        throw new Error('Failed to send emails to all students');
      }
      alert('Reminder emails sent to all students.');
    } catch (error) {
      console.error('Error sending emails to all students:', error.message);
    }
  };

  const handleCourseChange = (event) => {
    const course = event.target.value;
    setSelectedCourse(course);
    setSelectedSemester(''); // Clear semester when course changes
  };

  const handleSemesterChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSemester(selectedValue);
  };

  // Filter students based on selected course and semester
  const filteredStudents = students.filter((student) => {
    const courseMatch = !selectedCourse || student.course === selectedCourse;
    const semesterMatch =
      !selectedSemester || (student.semester && student.semester.toString() === selectedSemester);

    return courseMatch && semesterMatch;
  });

  return (
    <>
      <Navbar />
      <div className="student-details-container">
      <div className="filter-container">
  {/* Department and Semester Line */}
  <div className="department-semester-line">
    <div className="filter-element">
      <label htmlFor="course">Select Department:</label>
      <select id="course" value={selectedCourse} onChange={handleCourseChange}>
        <option value="">All</option>
        <option value="B.Tech CSE">Computer Science (CSE)</option>
        <option value="B.Tech ECE">Electronics and Communication (EC)</option>
        <option value="MCA">MCA</option>
        <option value="BCA">BCA</option>
        <option value="BBA">BBA</option>
      </select>
    </div>

    <div className="filter-element">
      <label htmlFor="semester">Select Semester:</label>
      <select id="semester" value={selectedSemester} onChange={handleSemesterChange}>
        <option value="">All</option>
        <option value="1">Semester 1</option>
        <option value="2">Semester 2</option>
        <option value="3">Semester 3</option>
        <option value="4">Semester 4</option>
        <option value="5">Semester 5</option>
        <option value="6">Semester 6</option>
        <option value="7">Semester 7</option>
        <option value="8">Semester 8</option>
      </select>
    </div>
  </div>

  {/* Installment, Date, and Button Line */}
  <div className="installment-date-button-line">
    <div className="filter-element">
      <label htmlFor="installment">Select Installment:</label>
      <select
        id="installment"
        value={selectedInstallment}
        onChange={(e) => setSelectedInstallment(e.target.value)}
      >
        <option value="">Select Installment</option>
        <option value="1">Installment 1</option>
        <option value="2">Installment 2</option>
        <option value="3">Installment 3</option>
        <option value="4">Installment 4</option>
        <option value="5">Installment 5</option>
        <option value="6">Installment 6</option>
        <option value="7">Installment 7</option>
        <option value="8">Installment 8</option>
      </select>
    </div>

    <div className="filter-element">
      <label htmlFor="lastDate">Last Date for Payment:</label>
      <input
        type="date"
        id="lastDate"
        value={lastDate}
        onChange={(e) => setLastDate(e.target.value)}
      />
    </div>

    <button onClick={handleSendEmailToAll} className="send-all-button">
      Send Reminder to All
    </button>
  </div>
</div>

        <div className="student-list-container">
        <div className="table-responsive">
          <table className="student-details-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Admission Number</th>
                <th>Branch</th>
                <th>Semester</th>
                <th>Fee Installments</th>
                <th>Email Reminder</th>
              </tr>
            </thead>
            <tbody>
              {filteredStudents.map((student) => (
                <tr key={student._id}>
                  <td>{student.name}</td>
                  <td>{student.admissionNumber}</td>
                  <td>{student.course}</td>
                  <td>{student.semester}</td>
                  <td>
                    <div className="fee-table">
                      {[...Array(getInstallmentCount(student))].map((_, index) => {
                        const installmentNumber = index + 1;
                        const isPaid = student.installmentsPaid && student.installmentsPaid.includes(installmentNumber);

                        return (
                          <div key={installmentNumber} className="installment-item">
                            <div>Installment {installmentNumber}</div>
                            {isPaid ? (
                              <span className="paid-text">Paid</span>
                            ) : (
                              <button
                                onClick={() => handleFeePayment(student._id, installmentNumber)}
                                disabled={isPaid}
                                className="pay-button"
                              >
                                Pay
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </td>
                  <td>
                    {[...Array(getInstallmentCount(student))].map((_, index) => {
                      const installmentNumber = index + 1;
                      const isPaid = student.installmentsPaid && student.installmentsPaid.includes(installmentNumber);

                      return (
                        <div key={installmentNumber} className="installment-item">
                          {!isPaid && (
                            <button
                              onClick={() => handleSendEmail(student._id, installmentNumber)}
                              className="email-button"
                            >
                              Send Reminder
                            </button>
                          )}
                        </div>
                      );
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </div>
      </div>
    </>
  );
}

function getInstallmentCount(student) {
  if (student.course === 'MCA') {
    return student.feeCategory === 'Merit Lower Fee' ? 1 : 2;
  }
  return student.feeCategory === 'Merit Lower Fee' ? 4 : 8;
}

export default StudentDetailsPage;
