import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { baseurl } from '../../url';
import HodNavbar from './HodNavbar';
import styles from './AddSubjectForm.module.css';

const ShowAddedSubjects = ({ selectedSemester, selectedCourse }) => {
  const [addedSubjects, setAddedSubjects] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editedSubjects, setEditedSubjects] = useState([]);

  const fetchAddedSubjects = useCallback(async () => {
    try {
      const branch = localStorage.getItem('branch');
      if (selectedSemester && selectedSemester >= 1 && selectedCourse) {
        if (!branch) {
          console.error('Branch not found in localStorage.');
          return;
        }
        const response = await axios.get(`${baseurl}/api/hod/subjects`, {
          params: {
            semester: selectedSemester,
            course: selectedCourse,
            branch: branch,
          }
        });
        setAddedSubjects(response.data);
        setEditedSubjects(response.data);
      }
    } catch (error) {
      console.error('Error fetching added subjects:', error);
    }
  }, [selectedSemester, selectedCourse]);

  useEffect(() => {
    fetchAddedSubjects();
  }, [fetchAddedSubjects, selectedSemester, selectedCourse]);

  const handleEditSubject = (index) => {
    setEditingIndex(index);
  };

  const handleSaveSubject = async (index) => {
    try {
      const subjectId = addedSubjects[index]._id;
      await axios.put(`${baseurl}/api/hod/subjects/${subjectId}`, editedSubjects[index]);
      alert('Subject details updated successfully!');
      setEditingIndex(null);
      fetchAddedSubjects(); // Refresh data after saving
    } catch (error) {
      console.error('Error updating subject details:', error);
    }
  };

  const handleInputChange = (event, field, index, subIndex) => {
    const updatedSubjects = [...editedSubjects];
    updatedSubjects[index].subjects[subIndex][field] = event.target.value;
    setEditedSubjects(updatedSubjects);
  };

  const handleDeleteSubject = async (subjectIndex, subIndex) => {
    try {
      const subjectId = addedSubjects[subjectIndex]._id;
      const subjectToDelete = addedSubjects[subjectIndex].subjects[subIndex]; // Get the specific subject to delete
      if (!subjectToDelete) {
        console.error('Subject not found for deletion');
        return;
      }
      await axios.delete(`${baseurl}/api/hod/subjects/${subjectId}/sub/${subjectToDelete._id}`); // Adjust the endpoint based on your backend setup
      alert('Subject deleted successfully!');
      fetchAddedSubjects(); // Refresh data after deletion
    } catch (error) {
      console.error('Error deleting subject:', error);
    }
  };

  return (
    <div className={styles.table}>
      <table>
        <thead>
          <tr>
            <th>Subject Name</th>
            <th>Subject Code</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {addedSubjects.map((subject, subjectIndex) => (
            <React.Fragment key={subjectIndex}>
              {subject.subjects.map((sub, subIndex) => (
                <tr key={`${subjectIndex}-${subIndex}`}>
                  <td>
                    {editingIndex === subjectIndex ? (
                      <input
                        type="text"
                        value={editedSubjects[subjectIndex].subjects[subIndex].subjectName}
                        onChange={(e) => handleInputChange(e, 'subjectName', subjectIndex, subIndex)}
                      />
                    ) : (
                      sub.subjectName
                    )}
                  </td>
                  <td>
                    {editingIndex === subjectIndex ? (
                      <input
                        type="text"
                        value={editedSubjects[subjectIndex].subjects[subIndex].subjectCode}
                        onChange={(e) => handleInputChange(e, 'subjectCode', subjectIndex, subIndex)}
                      />
                    ) : (
                      sub.subjectCode
                    )}
                  </td>
                  <td>
                    {editingIndex === subjectIndex ? (
                      <button onClick={() => handleSaveSubject(subjectIndex)} className={styles.submitButton}>Save</button>
                    ) : (
                      <button onClick={() => handleEditSubject(subjectIndex)} className={styles.editButton}>Edit</button>
                    )}
                    <button onClick={() => handleDeleteSubject(subjectIndex, subIndex)} className={styles.removeButton}>Delete</button>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const AddSubjectForm = () => {
  const [selectedSemester, setSelectedSemester] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const [semester, setSemester] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [branch, setBranch] = useState('');
  const [courses, setCourses] = useState([]);

  const handleChangeSemester = (e) => {
    const value = e.target.value;
    setSelectedSemester(value);
    setSemester(value);
    setSubjects([]); // Clear subjects when semester changes
  };

  const handleChangeCourse = (e) => {
    setSelectedCourse(e.target.value);
  };

  useEffect(() => {
    const branchFromLocalStorage = localStorage.getItem('branch');
    setBranch(branchFromLocalStorage);
    // Update courses based on selected branch
    if (branchFromLocalStorage === 'CS') {
      setCourses(['B.Tech CSE', 'MCA', 'BBA', 'BCA']);
    } else if (branchFromLocalStorage === 'EC') {
      setCourses(['B.Tech ECE']);
    } else if (branchFromLocalStorage === 'EE') {
      setCourses(['B.Tech CSE', 'B.Tech ECE', 'MCA', 'BBA', 'BCA']);
    }
  }, []);

  useEffect(() => {
    const fetchSubjects = async () => {
      if (selectedSemester && selectedCourse) {
        try {
          const branchFromLocalStorage = localStorage.getItem('branch');
          const response = await axios.get(`${baseurl}/api/hod/subjects`, {
            params: {
              semester: selectedSemester,
              course: selectedCourse,
              branch: branchFromLocalStorage,
            }
          });
          setSubjects(response.data);
        } catch (err) {
          console.error('Error fetching subjects:', err);
        }
      }
    };

    fetchSubjects();
  }, [selectedSemester, selectedCourse]);

  const handleChange = (index, event) => {
    const values = [...subjects];
    if (event.target.name === 'subjectName') {
      values[index].subjectName = event.target.value;
    } else {
      values[index].subjectCode = event.target.value;
    }
    setSubjects(values);
  };

  const handleAddColumn = () => {
    if (semester < 3 && subjects.length < 8) {
      setSubjects([...subjects, { subjectName: '', subjectCode: '' }]);
    } else if (semester >= 3 && semester <= 8 && subjects.length < 9) {
      setSubjects([...subjects, { subjectName: '', subjectCode: '' }]);
    } else {
      alert('You have reached the maximum number of subjects for this semester.');
    }
  };

  const handleRemoveColumn = (index) => {
    const values = [...subjects];
    values.splice(index, 1);
    setSubjects(values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${baseurl}/api/hod/subjects`, { semester, subjects, branch, course: selectedCourse });
      alert('Subjects added successfully!');
      setSemester('');
      setSubjects([]);
      setShowAddForm(false); // Hide form after submission
    } catch (err) {
      console.error('Error adding subjects:', err);
      alert('Error adding subjects.');
    }
  };

  return (
    <div>
      <HodNavbar />
      <div className={styles.container}>
        {branch && (
          <>
            <label className={styles.label} htmlFor="courseFilter">Select Course:</label>
            <select className={styles.select} id="courseFilter" value={selectedCourse} onChange={handleChangeCourse}>
              <option value="">Select Course</option>
              {courses.map((course, index) => (
                <option key={index} value={course}>{course}</option>
              ))}
            </select>
          </>
        )}

        <label className={styles.label} htmlFor="semesterFilter">Select Semester:</label>
        <select className={styles.select} id="semesterFilter" value={selectedSemester} onChange={handleChangeSemester}>
          <option value="">Select Semester</option>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((sem) => (
            <option key={sem} value={sem}>{sem}</option>
          ))}
        </select>

        <button type="button" onClick={() => setShowAddForm(!showAddForm)} className={styles.toggleButton}>
          {showAddForm ? 'Hide Form' : 'Show Add Subjects Form'}
        </button>

        {showAddForm && (
          <form onSubmit={handleSubmit} className={styles.form}>
            <h3>Add Subjects</h3>
            {subjects.map((subject, index) => (
              <div key={index} className={styles.subjectRow}>
                <input
                  type="text"
                  name="subjectName"
                  placeholder="Subject Name"
                  value={subject.subjectName}
                  onChange={(e) => handleChange(index, e)}
                  required
                  className={styles.input}
                />
                <input
                  type="text"
                  name="subjectCode"
                  placeholder="Subject Code"
                  value={subject.subjectCode}
                  onChange={(e) => handleChange(index, e)}
                  required
                  className={styles.input}
                />
                <button type="button" onClick={() => handleRemoveColumn(index)} className={styles.removeButton}>Remove</button>
              </div>
            ))}
            <button type="button" onClick={handleAddColumn} className={styles.addButton}>Add Subject</button>
            <button type="submit" className={styles.submitButton}>Submit</button>
          </form>
        )}

        {selectedSemester && selectedCourse && (
          <ShowAddedSubjects selectedSemester={selectedSemester} selectedCourse={selectedCourse} />
        )}
      </div>
    </div>
  );
};

export default AddSubjectForm;
